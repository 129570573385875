import { useTranslations } from '@vocab/react';
import { HiddenVisually } from 'braid-design-system';
import { useCallback, memo, type ReactNode } from 'react';

import { savedSearchesPath } from 'src/config';
import type { SavedSearch } from 'src/store/saveSearch/saveSearch';

import DashboardColumn, {
  type JobItem,
} from '../DashboardColumn/DashboardColumn';
import DashboardItem from '../DashboardItem/DashboardItem';

import translations from './.vocab';
import useSavedSearchesDashboard from './useSavedSearchesDashboard';

const SavedSearchesDashboard = () => {
  const { t } = useTranslations(translations);
  const { displayState, savedSearches, totalCount, formatSummary } =
    useSavedSearchesDashboard();

  const label = t('saved search label', { totalCount });
  const viewAllLinkProps = {
    location: savedSearchesPath,
    analytics: 'dashboard-all-saved-searches-click',
    linkContext: {
      linkSection: 'Saved Searches',
    },
  };

  const renderJobItem = useCallback(
    (savedSearch: JobItem, index: number) => {
      const { id, query, countLabel, name } = savedSearch as SavedSearch;
      const summary = formatSummary(query.parameters);

      const translatedTitle = t('{countLabel} new job(s) for: {name}', {
        countLabel,
        count: Number(countLabel || 0),
        name,
        HiddenVisually: (children: ReactNode) => (
          <HiddenVisually key="screen-reader-only">{children}</HiddenVisually>
        ),
      });

      return (
        <DashboardItem
          key={id}
          isNew={true}
          headingProps={{
            analytics: 'dashboard-save-search-click',
            linkContext: {
              linkPosition: `${index + 1}`,
              linkSection: 'Saved Searches',
            },
            location: `/jobs?${query.searchQueryString}&savedsearchid=${id}`,
          }}
          heading={name}
          screenReaderHeading={translatedTitle}
          subheading={summary}
          count={countLabel}
        />
      );
    },
    [formatSummary, t],
  );

  return (
    <DashboardColumn
      type="savedSearches"
      dataAutomation="savedSearchDashboard"
      label={label}
      displayState={displayState}
      totalCount={totalCount}
      errorLinkProps={viewAllLinkProps}
      viewAllLinkProps={{
        ...viewAllLinkProps,
        screenReaderText: t('your saved searches'),
      }}
      jobs={savedSearches}
      renderJobItem={renderJobItem}
      emptyMessage={t('Use the Save search...')}
    />
  );
};

export default memo(SavedSearchesDashboard);
