import type { SafetyHelmetProps } from 'src/components/SafetyHelmet/SafetyHelmet';

import { generateMetaDescription } from '../utils';

import { useDescriptionContent } from './description/content';

export const useHomePageHead = () => {
  const descriptionContent = useDescriptionContent();
  const tags: SafetyHelmetProps = {
    script: [],
    meta: generateMetaDescription(descriptionContent),
  };

  return tags;
};
