import gql from 'graphql-tag';

export const CREATE_SAVED_SEARCH = gql`
  mutation CreateApacSavedSearch(
    $input: CreateApacSavedSearchInput!
    $languageCode: LanguageCodeIso
    $locale: Locale
    $cluster: SmarterSearchCluster
  ) {
    createApacSavedSearch(input: $input) {
      ... on CreateApacSavedSearchSuccess {
        savedSearch {
          id
          name(languageCode: $languageCode)
          countLabel(cluster: $cluster)
          query(languageCode: $languageCode) {
            searchQueryString
            parameters {
              type
              value
            }
          }
        }
      }
      ... on CreateApacSavedSearchFailure {
        error {
          message(locale: $locale)
        }
      }
    }
  }
`;

export const CREATE_UNREGISTERED_APAC_SAVED_SEARCH = gql`
  mutation CreateUnregisteredApacSavedSearchWithChecksum(
    $input: CreateUnregisteredApacSavedSearchWithChecksumInput!
    $locale: Locale
  ) {
    createUnregisteredApacSavedSearchWithChecksum(input: $input) {
      ... on CreateUnregisteredApacSavedSearchWithChecksumSuccess {
        id
      }
      ... on CreateUnregisteredApacSavedSearchWithChecksumFailure {
        error {
          message(locale: $locale)
        }
      }
    }
  }
`;
