import type { JobTag } from '@seek/chalice-types';
import { useHubble } from '@seek/hubble';
import { mapJobTagsToBadges } from '@seek/seek-jobs-analytics';
import {
  addTags,
  getSolSearchResultsDataProp,
  type SolMetadata,
} from '@seek/sol-js';
import { Box } from 'braid-design-system';
import {
  useCallback,
  useMemo,
  type ReactElement,
  type ComponentProps,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { selectExperimentsAsSolTags } from 'src/store/experiments/experimentHelpers';
import { useSelector } from 'src/store/react';

export interface SolTrackerProps {
  children: ReactElement;
  jobId: number | string;
  jobTags?: JobTag[];
  solMetadata: SolMetadata;
  wrapperBoxProps?: ComponentProps<typeof Box>;
}

const SolImpressionTracker = ({
  children,
  jobId,
  jobTags,
  solMetadata: solData = {},
  wrapperBoxProps = {},
}: SolTrackerProps) => {
  const hubble = useHubble();
  const experiments = useSelector((state) => state.experiments);
  const solMetaData = useMemo(() => {
    // Memoise the formatted experiments as the selector returns a new object on each call
    const formattedExperiments = selectExperimentsAsSolTags({ experiments });
    return addTags(solData, formattedExperiments);
  }, [solData, experiments]);

  const trackEvent = useCallback(() => {
    hubble.trackEvent('job_ad_snippet_viewed', {
      jobId: jobId?.toString(),
      jobBadges: mapJobTagsToBadges(jobTags),
      solMetadata: solMetaData,
    });
  }, [hubble, jobTags, jobId, solMetaData]);

  const { ref: trackedComponent } = useInView({
    onChange(inView) {
      if (inView) {
        trackEvent();
      }
    },
    delay: 1000,
    threshold: 0.5,
    triggerOnce: true,
  });

  const dataProps = useMemo(
    () => getSolSearchResultsDataProp(JSON.stringify(solMetaData)),
    [solMetaData],
  );

  return (
    <Box ref={trackedComponent} {...wrapperBoxProps} {...dataProps}>
      {children}
    </Box>
  );
};

export default SolImpressionTracker;
