import { useTranslations } from '@vocab/react';
import { Heading, HiddenVisually } from 'braid-design-system';
import { memo } from 'react';

import translations from './.vocab';

interface DashboardHeadingProps {
  label: string;
  count?: string | number;
}

const DashboardHeading = (props: DashboardHeadingProps) => {
  const { t } = useTranslations(translations);
  const { label, count } = props;

  return (
    <Heading component="span" level="3">
      <HiddenVisually>
        <h1>
          {count
            ? t('{count} {label}', { count: count.toString(), label })
            : label}
        </h1>
      </HiddenVisually>
      <span aria-hidden>{label}</span>
    </Heading>
  );
};

const memoized = memo<DashboardHeadingProps>(DashboardHeading);
export default memoized;
