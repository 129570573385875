import { useQuery } from '@apollo/client';

import { useAppConfig } from 'src/config/appConfig';
import { GET_SAVED_JOBS } from 'src/modules/graphql/queries/savedJobs';
import type { SavedJobs } from 'src/modules/graphql/queries/types';

import { resolveDisplayState } from '../utils';

const useSavedJobsDashboard = () => {
  const { locale } = useAppConfig();
  const { data, loading } = useQuery<SavedJobs>(GET_SAVED_JOBS, {
    variables: {
      locale,
    },
    ssr: false,
  });
  const displayState = resolveDisplayState({
    data: data?.viewer?.savedJobs.edges,
    loading,
  });

  return {
    displayState,
    data: data?.viewer?.savedJobs.edges ?? null,
    totalCount: data?.viewer?.savedJobs.total ?? 0,
  };
};

export default useSavedJobsDashboard;
