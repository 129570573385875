export type DisplayState = 'READY' | 'LOADING' | 'ERROR';

export const resolveDisplayState = ({
  data,
  loading,
}: {
  data: any | undefined | null;
  loading: boolean;
}): DisplayState => {
  let displayState: DisplayState;
  if (loading) {
    displayState = 'LOADING';
  } else if (!data) {
    displayState = 'ERROR';
  } else {
    displayState = 'READY';
  }
  return displayState;
};
