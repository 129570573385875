import "src/components/HomePage/HomePageBanner/Campaign/Campaign.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.11_babel-plugin-macros@3.1.0_less@4._s3olgoolgz4b3kpwukomiiqebi/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/Campaign/Campaign.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+VVy3LiMBC88xW6pCo+OOUnGHHZP9kStsBKbMmRxaN2i3/fQjwyGoMMqdz2BtMtTU/32H6r4iTfmDX5OyFk1fB9WAnNSyOUpGTL9GsYnhjzYDE5TN5Of2pL71hVCbkOjepcLgsWAF4qY1TrMpYOo+Er4+Klg2uxrhGhgnKElVPzIY3be1ohw1voyqJLVn6stdrIKhQtW3OXE2NOL/5wSkq15RohmnecGUqkOv9EeKd6MTQ2gZO820nAmVI1SrsHInjgwx5o2T7cicrUlCRFWQO8OQWrpDkLhzdldrZGSH61J174gk1h69ZefUNgDlkST3TD46nVcR4AAjML3IquwLGMmX+JTRomJNCnrL5dLQwP+46V/HjFTrMOcDrLuT4JlGi1W4AKpyTJo24Pa8uL3t/xe7TrpzoKIMz8cOmHKww7wXzekBtqvuW6594mqb+Js3gaNylVs2klvGBFSR4hWzglcRS9wFJGSZ67rBS3njrKZpSk+OKCkjhBtYHJeYAiQgeetf3kyK+WV4KRvtScS8JkRV7BA2ktCKxbzjrdMc+1qjgL9OzVKbTRzRodcmRMQg5fE3zemwCumUcS1nznYQEdNeq4oiS7xH0YzSAbZIDvy44k7HV6XRCncZoMqjNKkmE1Oab/4tYKSma5X7eQF92z7Pu7k0T/9e78qGa8LV+fAHcx4oHlD+8VfKd5pi+CxzZnPk+e2Zxn8/F4A17n7nTpo9N5opuORTd90J84eeS1PAgZfL48G4hVDubEhO/P6YsNffYPk8M/h7apy+4LAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.11_babel-plugin-macros@3.1.0_less@4._s3olgoolgz4b3kpwukomiiqebi/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = 'd125utj';
export var backgroundColour = 'var(--d125ut0)';
export var backgroundImage = 'var(--d125ut1)';
export var backgroundPosition = 'var(--d125ut2)';
export var containerDirection = 'var(--d125ut9)';
export var containerLayout = 'd125utg';
export var contentGutter = 'd125uth';
export var contentPaddingBottom = 'var(--d125utb)';
export var contentPaddingLeft = 'var(--d125utc)';
export var contentPaddingRight = 'var(--d125utd)';
export var contentPaddingTop = 'var(--d125uta)';
export var image = 'd125uti';
export var imageHeightVar = 'var(--d125ute)';
export var imageMinHeightVar = 'var(--d125utf)';
export var imageSubtitle = 'd125utn';
export var multiCampaign = 'd125utp';
export var nowrap = 'd125uto';
export var reverse = 'd125utq';
export var standaloneCampaign = 'd125utr';
export var subtitle = 'd125utm';
export var subtitleBackgroundImage = 'var(--d125ut6)';
export var subtitleColour = 'var(--d125ut5)';
export var subtitleImageHeight = 'var(--d125ut8)';
export var subtitleImageSize = 'var(--d125ut7)';
export var title = 'd125utl';
export var titleFontSize = 'var(--d125ut4)';
export var titleLimit = 'd125utk';
export var titleTopPadding = 'var(--d125ut3)';