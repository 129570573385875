import { useTranslations } from '@vocab/react';
import { Heading, Stack, Tiles } from 'braid-design-system';
import { useEffect } from 'react';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight.tsx';
import AnimatedWidth from 'src/components/AnimatedWidth/AnimatedWidth.tsx';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useLoggedOutRecommendations } from 'src/modules/hooks/useLoggedOutRecommendations';
import { useDispatch } from 'src/store/react';
import { saveSolReferencesForLoggedOutHomeRecsAction } from 'src/store/results/results';

import translations from './.vocab';
import LoggedOutRecsJobCard from './LoggedOutRecsJobCard/LoggedOutRecsJobCard';
import { RecsJobCardSkeleton } from './RecsJobCardSkeleton/RecsJobCardSkeleton';
import SignInRegisterCTACard from './SignInRegisterCTACard/SignInRegisterCTACard.tsx';

const JOB_CARDS_SKELETON_COUNT = 5;

const useLoggedOutRecsContent = () => {
  const { recommendations, loading, error } = useLoggedOutRecommendations();
  const dispatch = useDispatch();
  const analytics = useAnalyticsFacade();

  useEffect(() => {
    if (!loading) {
      analytics.loggedOutRecsImpression({
        cardDisplayedCount: !error ? recommendations.length : null,
      });

      if (recommendations.length) {
        dispatch(saveSolReferencesForLoggedOutHomeRecsAction(recommendations));
      }
    }
  }, [analytics, loading, recommendations, error, dispatch]);

  if (loading) {
    return new Array(Number(JOB_CARDS_SKELETON_COUNT))
      .fill(null)
      .map((_, index) => <RecsJobCardSkeleton key={index} />);
  }

  return recommendations.map((jobCard, index) => (
    <LoggedOutRecsJobCard
      key={`${jobCard.job.id}`}
      recs={jobCard}
      position={String(index + 1)}
    />
  ));
};

const LoggedOutRecsSection = () => {
  const { t } = useTranslations(translations);
  const cards = useLoggedOutRecsContent();
  const colNum = Boolean(cards.length) ? 3 : 2;

  return (
    <Stack space="large">
      <Heading level="3">{t('Recommended based on your activity')}</Heading>
      <AnimatedHeight changeKeys={[cards]}>
        <Tiles
          columns={{ mobile: 1, tablet: 2, desktop: colNum }}
          space="small"
        >
          <AnimatedWidth changeKeys={[cards]} changeDependsOn="parent">
            <SignInRegisterCTACard />
          </AnimatedWidth>
          {cards}
        </Tiles>
      </AnimatedHeight>
    </Stack>
  );
};

export default LoggedOutRecsSection;
