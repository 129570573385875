import { useTranslations } from '@vocab/react';
import {
  Badge,
  Bleed,
  Box,
  Card,
  HiddenVisually,
  Stack,
  Text,
} from 'braid-design-system';
import { memo, type ComponentProps, type ReactNode } from 'react';

import NavLink, { type TextLink } from 'src/components/NavLink/NavLink';
import { JobDetailsPageView } from 'src/handlers/JobDetails';

import translations from './.vocab';

import * as styles from './DashboardItem.css';
interface DashboardItemProps {
  isNew?: boolean;
  isExpired?: boolean;
  heading: string;
  screenReaderHeading: ReactNode | ReactNode[] | string;
  subheading?: ReactNode | string;
  count?: string;
  headingProps: Pick<
    ComponentProps<typeof TextLink>,
    'analytics' | 'linkContext' | 'location' | 'onClick'
  >;
}

const DashboardItem = ({
  isNew,
  isExpired,
  heading,
  screenReaderHeading,
  subheading,
  count,
  headingProps: { analytics, linkContext, location, onClick },
}: DashboardItemProps) => {
  const { t } = useTranslations(translations);
  const shouldShowNewLabel = isNew && (!count || (count && count !== '0'));
  const headingId = `dash_${(linkContext?.linkSection || '')
    .split(' ')
    .join('_')}_${linkContext?.linkPosition}`.toLowerCase();

  return (
    <Card component="article" data={{ automation: 'dashboardItem' }}>
      <NavLink
        className={styles.linkOverlay}
        data-automation="dashboardItemCardLink"
        analytics={analytics}
        location={location}
        linkContext={linkContext}
        onClick={onClick}
        aria-labelledby={headingId}
        onMouseEnter={() => JobDetailsPageView.preload()}
      />
      <HiddenVisually>
        <h4 id={headingId}>{screenReaderHeading}</h4>
      </HiddenVisually>
      {shouldShowNewLabel || isExpired ? (
        <Box position="absolute">
          <Bleed top="gutter">
            {shouldShowNewLabel ? (
              <Badge
                tone="positive"
                bleedY
                data={{
                  automation: 'dashboardItemNew',
                }}
              >
                {count
                  ? t('{count} new', {
                      count,
                    })
                  : t('New')}
              </Badge>
            ) : null}
            {isExpired ? (
              <Badge
                tone="neutral"
                bleedY
                data={{
                  automation: 'dashboardItemExpired',
                }}
              >
                {t('Expired')}
              </Badge>
            ) : null}
          </Bleed>
        </Box>
      ) : null}
      <Box aria-hidden="true">
        <Stack space="small">
          <Text
            weight="medium"
            maxLines={1}
            data={{
              automation: 'dashboardItemHeading',
            }}
          >
            {heading}
          </Text>
          <Text
            tone="secondary"
            maxLines={1}
            data={
              subheading
                ? {
                    automation: 'dashboardItemSubHeading',
                  }
                : undefined
            }
          >
            {subheading || <>&nbsp;</>}
          </Text>
        </Stack>
      </Box>
    </Card>
  );
};

const memoized = memo<DashboardItemProps>(DashboardItem);
export default memoized;
