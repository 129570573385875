import { StandaloneCampaign } from '../HomePageBanner';

import useSeekretCampaignContent from './useSeekretCampaignContent';

const SeekretCampaignBanner = () => {
  const props = useSeekretCampaignContent();

  return <StandaloneCampaign {...props} />;
};

export default SeekretCampaignBanner;
