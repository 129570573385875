import { useCallback, useEffect, useState } from 'react';

import {
  useAnalyticsFacade,
  isBrowserAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import type { RecommendedGlobalJob } from 'src/modules/graphql/queries/types';
import { useRecommendations } from 'src/modules/hooks/useRecommendations';
import { useSelector } from 'src/store/react';
import { selectSeekerId, selectSessionId } from 'src/store/selectors';

import type { JobItem } from '../DashboardColumn/DashboardColumn';
import { resolveDisplayState } from '../utils';

import type { RecJobItemJobType } from './RecommendedJobItem';

const mapToJobPropType = (job: RecommendedGlobalJob): RecJobItemJobType => ({
  id: job.job.id,
  title: job.job.title,
  advertiser: job.job.advertiser.name,
  isNew: Boolean(job.isNew),
  solMetadata: job.solMetadata,
});

export default function useRecommendedJobsDashboard() {
  const {
    loading,
    jobs,
    totalCount,
    solMetadata: solMetadataRecs,
  } = useRecommendations();
  const seekerId = useSelector(selectSeekerId);
  const sessionId = useSelector(selectSessionId);
  const analyticsFacade = useAnalyticsFacade();
  const batchId = 0;
  const isNewBatch = false;
  const displayState = resolveDisplayState({ data: jobs, loading });

  const [isViewedEventSent, setIsViewedEventSent] = useState(false);

  const mapJobItemProps = useCallback(
    (job: JobItem, index: number) => ({
      key: 'job' in job ? job.job.id : index,
      job: mapToJobPropType(job as RecommendedGlobalJob),
      seekerId,
      batchId,
      sessionId,
      order: index + 1,
    }),
    [seekerId, sessionId],
  );

  useEffect(() => {
    if (seekerId && sessionId && jobs !== undefined && !isViewedEventSent) {
      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.recommendedJobsImpression({
          solMetadata: solMetadataRecs,
        });
      }
      setIsViewedEventSent(true);
    }
  }, [
    analyticsFacade,
    batchId,
    isNewBatch,
    isViewedEventSent,
    jobs,
    seekerId,
    sessionId,
    setIsViewedEventSent,
    solMetadataRecs,
  ]);

  return {
    displayState,
    jobs,
    mapJobItemProps,
    totalCount,
  };
}
