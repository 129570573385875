import { useTranslations } from '@vocab/react';
import { HiddenVisually, IconArrow } from 'braid-design-system';
import { type ComponentProps, memo } from 'react';

import { ButtonLink } from 'src/components/NavLink/NavLink';

import translations from './.vocab';

interface ViewAllLinkProps
  extends Pick<ComponentProps<typeof ButtonLink>, 'linkContext'> {
  analytics: string;
  location: string;
  count?: number;
  screenReaderText: string;
}
const ViewAllLink = ({
  analytics,
  linkContext,
  location,
  count,
  screenReaderText,
}: ViewAllLinkProps) => {
  const { t } = useTranslations(translations);

  return (
    <ButtonLink
      analytics={analytics}
      linkContext={linkContext}
      data={{ automation: 'dashboardViewAllLink' }}
      location={location}
      variant="ghost"
      tone="neutral"
      icon={<IconArrow direction="right" />}
      iconPosition="trailing"
    >
      {t('View all')}
      {count ? (
        <span data-automation="dashboardSectionCount">{` (${count})`}</span>
      ) : (
        ''
      )}
      <HiddenVisually>{screenReaderText}</HiddenVisually>
    </ButtonLink>
  );
};

const memoized = memo<ViewAllLinkProps>(ViewAllLink);
export default memoized;
