import { Column, Columns } from 'braid-design-system';

import RecommendedJobsDashboard from './RecommendedJobsDashboard/RecommendedJobsDashboard';
import SavedJobsDashboard from './SavedJobsDashboard/SavedJobsDashboard';
import SavedSearchesDashboard from './SavedSearchesDashboard/SavedSearchesDashboard';

export const SignedInDashboard = () => (
  <Columns
    space={{ mobile: 'xxlarge', desktop: 'gutter' }}
    collapseBelow="desktop"
    data={{
      automation: 'signedInDashboard',
    }}
  >
    <Column>
      <RecommendedJobsDashboard />
    </Column>
    <Column>
      <SavedJobsDashboard />
    </Column>
    <Column>
      <SavedSearchesDashboard />
    </Column>
  </Columns>
);
export default SignedInDashboard;
