import { metrics } from '@seek/metrics-js';
import { Box, Inline, Stack, Text } from 'braid-design-system';
import { useEffect, type ComponentProps, type ReactNode } from 'react';

import type {
  RecommendedGlobalJob,
  SavedJob,
} from 'src/modules/graphql/queries/types';
import type { SavedSearch } from 'src/store/saveSearch/saveSearch';

import DashboardHeading from '../DashboardHeading/DashboardHeading';
import DashboardSectionError from '../DashboardSectionError/DashboardSectionError';
import DashboardSectionSkeleton from '../DashboardSectionSkeleton/DashboardSectionSkeleton';
import ViewAllLink from '../ViewAllLink/ViewAllLink';
import type { DisplayState } from '../utils';

export type JobItem = RecommendedGlobalJob | SavedJob | SavedSearch;

interface DashboardColumnProps {
  type: string;
  label: string;
  displayState: DisplayState;
  totalCount?: number;
  errorLinkProps: ComponentProps<typeof DashboardSectionError>['linkProps'];
  viewAllLinkProps: ComponentProps<typeof ViewAllLink>;
  dataAutomation: string;
  jobs?: JobItem[] | null;
  renderJobItem: (job: JobItem, index: number) => JSX.Element;
  emptyMessage?: ReactNode;
}

const DashboardColumn = ({
  label,
  displayState,
  totalCount,
  errorLinkProps,
  viewAllLinkProps: { analytics, linkContext, location, screenReaderText },
  dataAutomation,
  jobs,
  renderJobItem,
  emptyMessage,
  type,
}: DashboardColumnProps) => {
  const hasJobs = jobs && jobs.length > 0;
  const emptyText = emptyMessage ? (
    <Text tone="secondary" data={{ automation: 'dashboardColumnEmpty' }}>
      {emptyMessage}
    </Text>
  ) : null;

  useEffect(() => {
    if (displayState === 'ERROR') {
      metrics.count('ui_error', [`type:${type}`]);
    }
  }, [displayState, type]);

  return (
    <Box
      component="nav"
      display="flex"
      flexDirection="column"
      justifyContent="spaceBetween"
      height="full"
      aria-label={label}
      role="navigation"
      data-automation={dataAutomation}
    >
      <Stack space="large">
        {displayState === 'LOADING' ? (
          <DashboardSectionSkeleton />
        ) : (
          <>
            <DashboardHeading label={label} count={totalCount} />
            {displayState === 'ERROR' && (
              <DashboardSectionError label={label} linkProps={errorLinkProps} />
            )}
            {displayState === 'READY' &&
              (hasJobs ? (
                <Stack space="small">
                  {jobs!.map((job, index) => renderJobItem(job, index))}
                </Stack>
              ) : (
                emptyText
              ))}
          </>
        )}
      </Stack>
      {hasJobs ? (
        <Box paddingTop="large">
          <Inline space="none">
            <ViewAllLink
              analytics={analytics}
              linkContext={linkContext}
              location={location}
              count={totalCount}
              screenReaderText={screenReaderText}
            />
          </Inline>
        </Box>
      ) : null}
    </Box>
  );
};
export default DashboardColumn;
