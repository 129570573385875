import { useTranslations } from '@vocab/react';
import { HiddenVisually, PageBlock } from 'braid-design-system';
import { memo } from 'react';

import { LOGGED_OUT_RECS } from 'src/store/featureFlags/featureFlags';
import { useSelector } from 'src/store/react';
import { selectAuthenticated, selectFeatureFlag } from 'src/store/selectors';

import translations from './.vocab';
import SignedInDashboard from './SignedInDashboard/SignedInDashboard';
import LoggedOutRecsSection from './SignedOutDashboard/LoggedOutRecsSection/LoggedOutRecsSection';
import SignedOutDashboard from './SignedOutDashboard/SignedOutDashboard';

export const Dashboard = () => {
  const { t } = useTranslations(translations);
  const authenticated = useSelector(selectAuthenticated);
  const loggedOutRecs = useSelector(selectFeatureFlag(LOGGED_OUT_RECS));

  const renderSignOutDashboardLayout = loggedOutRecs ? (
    <LoggedOutRecsSection />
  ) : (
    <SignedOutDashboard />
  );

  return (
    <PageBlock component="section">
      <HiddenVisually>
        <h1>{t('Dashboard')}</h1>
      </HiddenVisually>

      {authenticated ? <SignedInDashboard /> : renderSignOutDashboardLayout}
    </PageBlock>
  );
};

export default memo(Dashboard);
