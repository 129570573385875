import { Column, Columns, PageBlock } from 'braid-design-system';
import { memo } from 'react';

import { useZoneFeatures } from 'src/config/appConfig';

import Campaign, { type CampaignProps } from './Campaign/Campaign';
import GenericAsiaBanner from './GenericAsiaBanner/GenericAsiaBanner';
import SeekretCampaignBanner from './SeekretCampaignBanner/SeekretCampaignBanner';

export type BannerProps = Omit<CampaignProps, 'type'>;

const HomePageBanner = () => {
  const { HOMEPAGE_BANNER_TYPE: homepageBannerType } = useZoneFeatures();

  const homepageBannerContent = getHomePageBannerContent(homepageBannerType);

  return <PageBlock>{homepageBannerContent}</PageBlock>;
};

// Campaign components that are ready to use out of the box
export const StandaloneCampaign = (props: BannerProps) => (
  <Campaign {...props} type="standalone" />
);

export const MultiCampaign = (props: [BannerProps, BannerProps]) => (
  <Columns collapseBelow="tablet" space={{ mobile: 'small', tablet: 'large' }}>
    <Column>
      <Campaign {...props[0]} type="multi-campaign" />
    </Column>
    <Column>
      <Campaign {...props[1]} type="multi-campaign" />
    </Column>
  </Columns>
);

const getHomePageBannerContent = (homepageBannerType: string | undefined) => {
  switch (homepageBannerType) {
    case 'SEEKRET_ANZ_BANNER':
      return <SeekretCampaignBanner />;
    case 'GENERIC_ASIA_BANNER':
      return <GenericAsiaBanner />;
  }
};

export default memo(HomePageBanner);
