import { Box, Stack } from 'braid-design-system';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';

const DASHBOARD_SECTION_ITEMS_COUNT = 3;
const skeletonGroups = new Array(DASHBOARD_SECTION_ITEMS_COUNT).fill(null);

const DashboardSectionSkeleton = () => (
  <Stack space="large">
    <SkeletonLine type="heading" level="3" width={150} />

    <Stack space="small">
      {skeletonGroups.map((_, id) => (
        <Box
          key={id}
          padding="gutter"
          borderRadius="large"
          boxShadow="borderNeutralLight"
        >
          <Stack space="small">
            <SkeletonLine type="text" width={200} />
            <SkeletonLine type="text" width={260} />
          </Stack>
        </Box>
      ))}
    </Stack>

    <SkeletonLine type="button" width={160} />
  </Stack>
);

export default DashboardSectionSkeleton;
