
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJOZXcgPEhpZGRlblZpc3VhbGx5PnJlY29tbWVuZGF0aW9uPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIEF0IHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6IlJlY29tbWVuZGVkIHt0b3RhbENvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IiwicmVjb21tZW5kZWQgam9icyI6InJlY29tbWVuZGVkIGpvYnMiLCJDb21wbGV0ZSB5b3VyIHByb2ZpbGUuLi4iOiI8TmF2TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9OYXZMaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiI8SGlkZGVuVmlzdWFsbHk+UmVrb21lbmRhc2k8L0hpZGRlblZpc3VhbGx5PiBiYXJ1Iiwie3RpdGxlfS4gQXQge2FkdmVydGlzZXJ9Ijoie3RpdGxlfSA8SGlkZGVuVmlzdWFsbHk+LiBEaSB7YWR2ZXJ0aXNlcn08L0hpZGRlblZpc3VhbGx5PiIsIlJlY29tbWVuZGVkIGpvYnMiOiJSZWtvbWVuZGFzaSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7bG93b25nYW4ga2VyamF9IG9uZSB7bG93b25nYW4ga2VyamF9IG90aGVyIHtsb3dvbmdhbiBrZXJqYX19IiwicmVjb21tZW5kZWQgam9icyI6Imxvd29uZ2FuIGtlcmphIHlhbmcgZGlyZWtvbWVuZGFzaSIsIkNvbXBsZXRlIHlvdXIgcHJvZmlsZS4uLiI6IjxOYXZMaW5rPlBlcmJhcnVpIHByb2ZpbCBBbmRhPC9OYXZMaW5rPiBhdGF1IG11bGFpIG1lbmNhcmkgbG93b25nYW4gdW50dWsgbWVuZGFwYXRrYW4gcmVrb21lbmRhc2kgbG93b25nYW4geWFuZyBkaXBlcnNvbmFsaXNhc2kgZGkgc2luaS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiI8SGlkZGVuVmlzdWFsbHk+UmVrb21lbmRhc2k8L0hpZGRlblZpc3VhbGx5PiBiYXJ1Iiwie3RpdGxlfS4gQXQge2FkdmVydGlzZXJ9Ijoie3RpdGxlfSA8SGlkZGVuVmlzdWFsbHk+LiBEaSB7YWR2ZXJ0aXNlcn08L0hpZGRlblZpc3VhbGx5PiIsIlJlY29tbWVuZGVkIGpvYnMiOiJSZWtvbWVuZGFzaSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7bG93b25nYW4ga2VyamF9IG9uZSB7bG93b25nYW4ga2VyamF9IG90aGVyIHtsb3dvbmdhbiBrZXJqYX19IiwicmVjb21tZW5kZWQgam9icyI6Imxvd29uZ2FuIGtlcmphIHlhbmcgZGlyZWtvbWVuZGFzaSIsIkNvbXBsZXRlIHlvdXIgcHJvZmlsZS4uLiI6IjxOYXZMaW5rPlBlcmJhcnVpIHByb2ZpbCBBbmRhPC9OYXZMaW5rPiBhdGF1IG11bGFpIG1lbmNhcmkgbG93b25nYW4gdW50dWsgbWVuZGFwYXRrYW4gcmVrb21lbmRhc2kgbG93b25nYW4geWFuZyBkaXBlcnNvbmFsaXNhc2kgZGkgc2luaS4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiI8SGlkZGVuVmlzdWFsbHk+4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizPC9IaWRkZW5WaXN1YWxseT7guYPguKvguKHguYgiLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4g4LiX4Li14LmIIHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6Int0b3RhbENvdW50LCBwbHVyYWwsID0wIHvguIfguLLguJnguYHguJnguLDguJnguLN9IG9uZSB74LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizfSBvdGhlciB74LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizfX0iLCJyZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizIiwiQ29tcGxldGUgeW91ciBwcm9maWxlLi4uIjoiPE5hdkxpbms+4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9OYXZMaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiI8SGlkZGVuVmlzdWFsbHk+4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizPC9IaWRkZW5WaXN1YWxseT7guYPguKvguKHguYgiLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4g4LiX4Li14LmIIHthZHZlcnRpc2VyfTwvSGlkZGVuVmlzdWFsbHk+IiwiUmVjb21tZW5kZWQgam9icyI6Int0b3RhbENvdW50LCBwbHVyYWwsID0wIHvguIfguLLguJnguYHguJnguLDguJnguLN9IG9uZSB74LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizfSBvdGhlciB74LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizfX0iLCJyZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4LizIiwiQ29tcGxldGUgeW91ciBwcm9maWxlLi4uIjoiPE5hdkxpbms+4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9OYXZMaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJbw5Hhur3hur3hur3FtSA8SGlkZGVuVmlzdWFsbHk+xZnhur3hur3Dp8O2w7ZtzIJtzILhur3hur3guIHguLXguYnGjMSDxIPhua/DrMOsw7bDtuC4geC4teC5iTwvSGlkZGVuVmlzdWFsbHk+XSIsInt0aXRsZX0uIEF0IHthZHZlcnRpc2VyfSI6Ilt7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIOG6rOG5ryB7YWR2ZXJ0aXNlcn08L0hpZGRlblZpc3VhbGx5Pl0iLCJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjOG6veG6veG6vcaMIHt0b3RhbENvdW50LHBsdXJhbCw9MHvEtcO2w7bDtsOffSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX1dIiwicmVjb21tZW5kZWQgam9icyI6IlvFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozhur3hur3hur3GjCDEtcO2w7bDtsOfxaFdIiwiQ29tcGxldGUgeW91ciBwcm9maWxlLi4uIjoiWzxOYXZMaW5rPseZxqXGjMSDxIPhua/hur3hur0gw73DvcO2w7bHmseaxZkgxqXFmcO2w7bGksOsw6zGmuG6veG6vTwvTmF2TGluaz4gw7bDtsO2xZkgxaHhua/Eg8SDxIPFmeG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnDrMOsw6zguIHguLXguYnEoyDGksO2w7bDtsWZIMS1w7bDtsO2w5/FoSDhua/DtsO2w7YgxKPhur3hur3hur3hua8gxqXhur3hur3hur3FmcWhw7bDtsO24LiB4Li14LmJxIPEg8SDxprDrMOsw6zFoeG6veG6veG6vcaMIMS1w7bDtsO2w58gxZnhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaMxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEg4bip4bq94bq94bq9xZnhur3hur3hur0uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXcgcmVjb21tZW5kYXRpb24iOiJbw5Hhur3hur3hur3FtSA8SGlkZGVuVmlzdWFsbHk+xZnhur3hur3Dp8O2w7ZtzIJtzILhur3hur3guIHguLXguYnGjMSDxIPhua/DrMOsw7bDtuC4geC4teC5iTwvSGlkZGVuVmlzdWFsbHk+XSIsInt0aXRsZX0uIEF0IHthZHZlcnRpc2VyfSI6Ilt7dGl0bGV9IDxIaWRkZW5WaXN1YWxseT4uIOG6rOG5ryB7YWR2ZXJ0aXNlcn08L0hpZGRlblZpc3VhbGx5Pl0iLCJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjOG6veG6veG6vcaMIHt0b3RhbENvdW50LHBsdXJhbCw9MHvEtcO2w7bDtsOffSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX1dIiwicmVjb21tZW5kZWQgam9icyI6IlvFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozhur3hur3hur3GjCDEtcO2w7bDtsOfxaFdIiwiQ29tcGxldGUgeW91ciBwcm9maWxlLi4uIjoiWzxOYXZMaW5rPseZxqXGjMSDxIPhua/hur3hur0gw73DvcO2w7bHmseaxZkgxqXFmcO2w7bGksOsw6zGmuG6veG6vTwvTmF2TGluaz4gw7bDtsO2xZkgxaHhua/Eg8SDxIPFmeG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnDrMOsw6zguIHguLXguYnEoyDGksO2w7bDtsWZIMS1w7bDtsO2w5/FoSDhua/DtsO2w7YgxKPhur3hur3hur3hua8gxqXhur3hur3hur3FmcWhw7bDtsO24LiB4Li14LmJxIPEg8SDxprDrMOsw6zFoeG6veG6veG6vcaMIMS1w7bDtsO2w58gxZnhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaMxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEg4bip4bq94bq94bq9xZnhur3hur3hur0uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    