import { useTranslations } from '@vocab/react';
import { Box, Button } from 'braid-design-system';
import { useCallback, type MouseEvent } from 'react';

import LeftAlignedButtonLabel from 'src/components/LeftAlignedButtonLabel/LeftAlignedButtonLabel';
import { useAppConfig } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useEnrichedLocation } from 'src/modules/enriched-location';
import { getClassificationName } from 'src/modules/refine-job-search';
import { linkNavigate } from 'src/store/location/location';
import { useDispatch } from 'src/store/react';
import type { SearchQuerySummary } from 'src/store/recentSearches/recentSearches';
import type { Country } from 'src/types/globals';

import translations from '../.vocab';

export interface RecentSearchItemProps {
  listIndex: number;
  listDisplayCount: number;
  search: SearchQuerySummary;
}

const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const getClassificationDisplayStr = (classifications: string[]) => {
  switch (classifications.length) {
    case 0:
      return '';
    case 1:
      return classifications[0];
    default:
      return `${classifications[0]} (${classifications.length})`;
  }
};

export const getClassificationDisplayStrForTest = getClassificationDisplayStr;

const RecentSearchItem = ({
  search: { query },
  listIndex,
  listDisplayCount,
}: RecentSearchItemProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();
  const enrichedLocation = useEnrichedLocation({
    location: {
      pathname: '/jobs',
      query,
    },
  });

  const { t } = useTranslations(translations);

  const locationDisplayDefault: Record<Country, string> = {
    AU: t('Australia'),
    NZ: t('New Zealand'),
    HK: t('Hong Kong'),
    TH: t('Thailand'),
    ID: t('Indonesia'),
    MY: t('Malaysia'),
    PH: t('Philippines'),
    SG: t('Singapore'),
  };
  const classificationIds = query.classification?.split(',')?.sort() || [];
  const { zone, country, language: languageCode } = useAppConfig();

  const classifications = classificationIds
    .map((id) => getClassificationName(Number(id), zone, languageCode))
    .filter(Boolean);

  const classificationsDisplayStr =
    getClassificationDisplayStr(classifications);

  const keywordDisplayStr = query.keywords
    ? capitalizeString(query.keywords)
    : t('All jobs');

  let locationDisplayStr = '';
  if (query.where) {
    locationDisplayStr = query.where;
  } else if (!query.where && !classifications.length) {
    locationDisplayStr = locationDisplayDefault[country];
  }

  const title = [
    keywordDisplayStr,
    classifications.join(' · '),
    locationDisplayStr,
  ]
    .filter(Boolean)
    .join('\n');

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      analyticsFacade.recentSearchClicked({
        cardPosition: `${listIndex}`,
        cardDisplayedCount: `${listDisplayCount}`,
        isExitLink: false,
      });

      dispatch(
        linkNavigate({
          event,
          location: enrichedLocation,
        }),
      );
    },
    [analyticsFacade, dispatch, enrichedLocation, listDisplayCount, listIndex],
  );

  const displayStr = [
    keywordDisplayStr,
    classificationsDisplayStr,
    locationDisplayStr,
  ]
    .filter(Boolean)
    .join(' · ');

  return (
    <Box title={title}>
      <Button
        variant="soft"
        size="small"
        tone="neutral"
        onClick={onClick}
        data={{ automation: `recent-search-button-${listIndex}` }}
      >
        <LeftAlignedButtonLabel label={displayStr} />
      </Button>
    </Box>
  );
};

export default RecentSearchItem;
