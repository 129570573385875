import { useTranslations } from '@vocab/react';
import { Text } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import { TextLink } from 'src/components/NavLink/NavLink';

import translations from './.vocab';

interface DashboardSectionErrorProps {
  linkProps: Pick<ComponentProps<typeof TextLink>, 'analytics' | 'location'>;
  label: string;
}

const DashboardSectionError = ({
  linkProps: { analytics, location },
  label,
}: DashboardSectionErrorProps) => {
  const { t } = useTranslations(translations);

  return (
    <Text
      data={{
        automation: 'dashboardSectionError',
      }}
      tone="secondary"
      size="small"
    >
      {t('The {label} summary is...', {
        label,
        Link: (children: ReactNode) => (
          <TextLink key="link" analytics={analytics} location={location}>
            {children}
          </TextLink>
        ),
      })}
    </Text>
  );
};

export default DashboardSectionError;
