
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiRXhwaXJlZCI6IkV4cGlyZWQiLCJFeHBpcmVkIGpvYiI6IkV4cGlyZWQgPEhpZGRlblZpc3VhbGx5PmpvYjwvSGlkZGVuVmlzdWFsbHk+In0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJCYXJ1Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IGJhcnUiLCJFeHBpcmVkIjoiQmVyYWtoaXIiLCJFeHBpcmVkIGpvYiI6IjxIaWRkZW5WaXN1YWxseT5Mb3dvbmdhbjwvSGlkZGVuVmlzdWFsbHk+IGtlZGFsdXdhcnNhIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJCYXJ1Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IGJhcnUiLCJFeHBpcmVkIjoiQmVyYWtoaXIiLCJFeHBpcmVkIGpvYiI6IjxIaWRkZW5WaXN1YWxseT5Mb3dvbmdhbjwvSGlkZGVuVmlzdWFsbHk+IGtlZGFsdXdhcnNhIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiLguYPguKvguKHguYgiLCJ7Y291bnR9IG5ldyI6Intjb3VudH0g4LmD4Lir4Lih4LmIIiwiRXhwaXJlZCI6IuC4q+C4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyIsIkV4cGlyZWQgam9iIjoiPEhpZGRlblZpc3VhbGx5PuC4h+C4suC4mTwvSGlkZGVuVmlzdWFsbHk+4LiX4Li14LmI4Lir4Lih4LiU4Lit4Liy4Lii4Li4In0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiLguYPguKvguKHguYgiLCJ7Y291bnR9IG5ldyI6Intjb3VudH0g4LmD4Lir4Lih4LmIIiwiRXhwaXJlZCI6IuC4q+C4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyIsIkV4cGlyZWQgam9iIjoiPEhpZGRlblZpc3VhbGx5PuC4h+C4suC4mTwvSGlkZGVuVmlzdWFsbHk+4LiX4Li14LmI4Lir4Lih4LiU4Lit4Liy4Lii4Li4In0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJbw5Hhur3hur3hur3FtV0iLCJ7Y291bnR9IG5ldyI6Ilt7Y291bnR9IOC4geC4teC5ieG6veG6veG6vcW1XSIsIkV4cGlyZWQiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xoxdIiwiRXhwaXJlZCBqb2IiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgPEhpZGRlblZpc3VhbGx5PsS1w7bDtsO2w588L0hpZGRlblZpc3VhbGx5Pl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJbw5Hhur3hur3hur3FtV0iLCJ7Y291bnR9IG5ldyI6Ilt7Y291bnR9IOC4geC4teC5ieG6veG6veG6vcW1XSIsIkV4cGlyZWQiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xoxdIiwiRXhwaXJlZCBqb2IiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgPEhpZGRlblZpc3VhbGx5PsS1w7bDtsO2w588L0hpZGRlblZpc3VhbGx5Pl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    