
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJTYXZlZCB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4iLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5Pi4gQXQge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJVc2UgdGhlIFNhdmUuLi4iOiJVc2UgdGhlIFNhdmUgYnV0dG9uIG9uIGVhY2ggam9iIGxpc3RpbmcgdG8gc2F2ZSBpdCBmb3IgbGF0ZXIuIFlvdSBjYW4gdGhlbiBhY2Nlc3MgdGhlbSBvbiBhbGwgeW91ciBkZXZpY2VzLiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJMb3dvbmdhbiB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7a2VyamEgdGVyc2ltcGFufSBvbmUge2tlcmphIHRlcnNpbXBhbn0gb3RoZXIge2tlcmphIHRlcnNpbXBhbn19IiwiRXhwaXJlZCBqb2IiOiI8SGlkZGVuVmlzdWFsbHk+TG93b25nYW48L0hpZGRlblZpc3VhbGx5PiBrZWRhbHV3YXJzYSIsInt0aXRsZX0uIEF0IHthZHZlcnRpc2VyfSI6Int0aXRsZX08SGlkZGVuVmlzdWFsbHk+LiBEaSB7YWR2ZXJ0aXNlcn08L0hpZGRlblZpc3VhbGx5PiIsInlvdXIgc2F2ZWQgam9icyI6Ikxvd29uZ2FuIGtlcmphIHlhbmcga2FtdSBzaW1wYW4iLCJVc2UgdGhlIFNhdmUuLi4iOiJHdW5ha2FuIHRvbWJvbCBTaW1wYW4gZGkgc2V0aWFwIGxvd29uZ2FuIGtlcmphIGd1bmEgbWVueWltcGFubnlhIHVudHVrIGxhaW4gd2FrdHUuIEthbXUgZGFwYXQgbWVuZ2Frc2VzbnlhIGRpIHNlbXVhIHBlcmFuZ2thdCBkaSBsYWluIHdha3R1LiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJMb3dvbmdhbiB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7a2VyamEgdGVyc2ltcGFufSBvbmUge2tlcmphIHRlcnNpbXBhbn0gb3RoZXIge2tlcmphIHRlcnNpbXBhbn19IiwiRXhwaXJlZCBqb2IiOiI8SGlkZGVuVmlzdWFsbHk+TG93b25nYW48L0hpZGRlblZpc3VhbGx5PiBrZWRhbHV3YXJzYSIsInt0aXRsZX0uIEF0IHthZHZlcnRpc2VyfSI6Int0aXRsZX08SGlkZGVuVmlzdWFsbHk+LiBEaSB7YWR2ZXJ0aXNlcn08L0hpZGRlblZpc3VhbGx5PiIsInlvdXIgc2F2ZWQgam9icyI6Ikxvd29uZ2FuIGtlcmphIHlhbmcga2FtdSBzaW1wYW4iLCJVc2UgdGhlIFNhdmUuLi4iOiJHdW5ha2FuIHRvbWJvbCBTaW1wYW4gZGkgc2V0aWFwIGxvd29uZ2FuIGtlcmphIGd1bmEgbWVueWltcGFubnlhIHVudHVrIGxhaW4gd2FrdHUuIEthbXUgZGFwYXQgbWVuZ2Frc2VzbnlhIGRpIHNlbXVhIHBlcmFuZ2thdCBkaSBsYWluIHdha3R1LiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiLguJrguLHguJnguJfguLbguIF7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge+C4h+C4suC4mX0gb3RoZXIge+C4h+C4suC4mX19IiwiRXhwaXJlZCBqb2IiOiI8SGlkZGVuVmlzdWFsbHk+4LiH4Liy4LiZPC9IaWRkZW5WaXN1YWxseT7guJfguLXguYjguKvguKHguJTguK3guLLguKLguLgiLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5PiDguJfguLXguYgge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiLguIfguLLguJnguJfguLXguYjguJrguLHguJnguJfguLbguIHguYTguKfguYnguILguK3guIfguITguLjguJMiLCJVc2UgdGhlIFNhdmUuLi4iOiLguYPguIrguYnguJvguLjguYjguKHguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLLguKrguLPguKvguKPguLHguJrguJrguLHguJnguJfguLbguIHguYTguKfguYnguYPguIrguYnguYPguJnguKDguLLguKLguKvguKXguLHguIcg4LmA4Lie4Li34LmI4Lit4LiX4Li14LmI4LiE4Li44LiT4Liq4Liy4Lih4Liy4Lij4LiW4LmA4LiC4LmJ4Liy4LiW4Li24LiH4LmE4LiU4LmJ4LiX4Li44LiB4Lit4Li44Lib4LiB4Lij4LiT4LmMIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiLguJrguLHguJnguJfguLbguIF7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge+C4h+C4suC4mX0gb3RoZXIge+C4h+C4suC4mX19IiwiRXhwaXJlZCBqb2IiOiI8SGlkZGVuVmlzdWFsbHk+4LiH4Liy4LiZPC9IaWRkZW5WaXN1YWxseT7guJfguLXguYjguKvguKHguJTguK3guLLguKLguLgiLCJ7dGl0bGV9LiBBdCB7YWR2ZXJ0aXNlcn0iOiJ7dGl0bGV9PEhpZGRlblZpc3VhbGx5PiDguJfguLXguYgge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT4iLCJ5b3VyIHNhdmVkIGpvYnMiOiLguIfguLLguJnguJfguLXguYjguJrguLHguJnguJfguLbguIHguYTguKfguYnguILguK3guIfguITguLjguJMiLCJVc2UgdGhlIFNhdmUuLi4iOiLguYPguIrguYnguJvguLjguYjguKHguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLLguKrguLPguKvguKPguLHguJrguJrguLHguJnguJfguLbguIHguYTguKfguYnguYPguIrguYnguYPguJnguKDguLLguKLguKvguKXguLHguIcg4LmA4Lie4Li34LmI4Lit4LiX4Li14LmI4LiE4Li44LiT4Liq4Liy4Lih4Liy4Lij4LiW4LmA4LiC4LmJ4Liy4LiW4Li24LiH4LmE4LiU4LmJ4LiX4Li44LiB4Lit4Li44Lib4LiB4Lij4LiT4LmMIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJbxaDEg8SDxIPhub3hur3hur3hur3GjCB7dG90YWxDb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5/FoX19XSIsIkV4cGlyZWQgam9iIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMIDxIaWRkZW5WaXN1YWxseT7EtcO2w7bDtsOfPC9IaWRkZW5WaXN1YWxseT5dIiwie3RpdGxlfS4gQXQge2FkdmVydGlzZXJ9IjoiW3t0aXRsZX08SGlkZGVuVmlzdWFsbHk+LiDhuqzhua8ge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT5dIiwieW91ciBzYXZlZCBqb2JzIjoiW8O9w73DtsO2x5rHmsWZIMWhxIPEg+G5veG6veG6vcaMIMS1w7bDtsOfxaFdIiwiVXNlIHRoZSBTYXZlLi4uIjoiW8eZxaHhur3hur3hur0g4bmv4bip4bq94bq94bq9IMWgxIPEg8SD4bm94bq94bq94bq9IMOfx5rHmsea4bmv4bmvw7bDtsO24LiB4Li14LmJIMO2w7bDtuC4geC4teC5iSDhur3hur3hur3Eg8SDxIPDp+G4qSDEtcO2w7bDtsOfIMaaw6zDrMOsxaHhua/DrMOsw6zguIHguLXguYnEoyDhua/DtsO2w7YgxaHEg8SDxIPhub3hur3hur3hur0gw6zDrMOs4bmvIMaSw7bDtsO2xZkgxprEg8SDxIPhua/hur3hur3hur3FmS4gw53DtsO2w7bHmseax5ogw6fEg8SDxIPguIHguLXguYkg4bmv4bip4bq94bq94bq94LiB4Li14LmJIMSDxIPEg8Onw6fhur3hur3hur3FocWhIOG5r+G4qeG6veG6veG6vW3MgiDDtsO2w7bguIHguLXguYkgxIPEg8SDxprGmiDDvcO9w73DtsO2w7bHmseax5rFmSDGjOG6veG6veG6veG5vcOsw6zDrMOn4bq94bq94bq9xaEuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzYXZlZCBqb2IgbGFiZWwiOiJbxaDEg8SDxIPhub3hur3hur3hur3GjCB7dG90YWxDb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5/FoX19XSIsIkV4cGlyZWQgam9iIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMIDxIaWRkZW5WaXN1YWxseT7EtcO2w7bDtsOfPC9IaWRkZW5WaXN1YWxseT5dIiwie3RpdGxlfS4gQXQge2FkdmVydGlzZXJ9IjoiW3t0aXRsZX08SGlkZGVuVmlzdWFsbHk+LiDhuqzhua8ge2FkdmVydGlzZXJ9PC9IaWRkZW5WaXN1YWxseT5dIiwieW91ciBzYXZlZCBqb2JzIjoiW8O9w73DtsO2x5rHmsWZIMWhxIPEg+G5veG6veG6vcaMIMS1w7bDtsOfxaFdIiwiVXNlIHRoZSBTYXZlLi4uIjoiW8eZxaHhur3hur3hur0g4bmv4bip4bq94bq94bq9IMWgxIPEg8SD4bm94bq94bq94bq9IMOfx5rHmsea4bmv4bmvw7bDtsO24LiB4Li14LmJIMO2w7bDtuC4geC4teC5iSDhur3hur3hur3Eg8SDxIPDp+G4qSDEtcO2w7bDtsOfIMaaw6zDrMOsxaHhua/DrMOsw6zguIHguLXguYnEoyDhua/DtsO2w7YgxaHEg8SDxIPhub3hur3hur3hur0gw6zDrMOs4bmvIMaSw7bDtsO2xZkgxprEg8SDxIPhua/hur3hur3hur3FmS4gw53DtsO2w7bHmseax5ogw6fEg8SDxIPguIHguLXguYkg4bmv4bip4bq94bq94bq94LiB4Li14LmJIMSDxIPEg8Onw6fhur3hur3hur3FocWhIOG5r+G4qeG6veG6veG6vW3MgiDDtsO2w7bguIHguLXguYkgxIPEg8SDxprGmiDDvcO9w73DtsO2w7bHmseax5rFmSDGjOG6veG6veG6veG5vcOsw6zDrMOn4bq94bq94bq9xaEuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    