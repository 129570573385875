
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwic2F2ZWQgc2VhcmNoIGxhYmVsIjoiU2F2ZWQge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3NlYXJjaH0gb25lIHtzZWFyY2h9IG90aGVyIHtzZWFyY2hlc319IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6InlvdXIgc2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7bG93b25nYW4ga2VyamF9IG9uZSB7bG93b25nYW4ga2VyamF9IG90aGVyIHtsb3dvbmdhbiBrZXJqYX19IGZvcjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJzYXZlZCBzZWFyY2ggbGFiZWwiOiJQZW5jYXJpYW4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3RlcnNpbXBhbn0gb25lIHt0ZXJzaW1wYW59IG90aGVyIHt0ZXJzaW1wYW59fSIsInlvdXIgc2F2ZWQgc2VhcmNoZXMiOiJQZW5jYXJpYW4geWFuZyBrYW11IHNpbXBhbiIsIlVzZSB0aGUgU2F2ZSBzZWFyY2guLi4iOiJHdW5ha2FuIHRvbWJvbCBTaW1wYW4gcGVuY2FyaWFuIGRpIGJhd2FoIGhhc2lsIHBlbmNhcmlhbiB1bnR1ayBtZW55aW1wYW4gcGVuY2FyaWFuIGRhbiBtZW5lcmltYSBzZXRpYXAgbG93b25nYW4ga2VyamEgYmFydS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7bG93b25nYW4ga2VyamF9IG9uZSB7bG93b25nYW4ga2VyamF9IG90aGVyIHtsb3dvbmdhbiBrZXJqYX19IGZvcjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJzYXZlZCBzZWFyY2ggbGFiZWwiOiJQZW5jYXJpYW4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3RlcnNpbXBhbn0gb25lIHt0ZXJzaW1wYW59IG90aGVyIHt0ZXJzaW1wYW59fSIsInlvdXIgc2F2ZWQgc2VhcmNoZXMiOiJQZW5jYXJpYW4geWFuZyBrYW11IHNpbXBhbiIsIlVzZSB0aGUgU2F2ZSBzZWFyY2guLi4iOiJHdW5ha2FuIHRvbWJvbCBTaW1wYW4gcGVuY2FyaWFuIGRpIGJhd2FoIGhhc2lsIHBlbmNhcmlhbiB1bnR1ayBtZW55aW1wYW4gcGVuY2FyaWFuIGRhbiBtZW5lcmltYSBzZXRpYXAgbG93b25nYW4ga2VyamEgYmFydS4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0g4LmD4Lir4Lih4LmIIHtjb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge+C4h+C4suC4mX0gb3RoZXIge+C4h+C4suC4mX19IOC4quC4s+C4q+C4o+C4seC4mjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJzYXZlZCBzZWFyY2ggbGFiZWwiOiLguJrguLHguJnguJfguLbguIF7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4LiyfSBvbmUge+C4geC4suC4o+C4hOC5ieC4meC4q+C4sn0gb3RoZXIge+C4geC4suC4o+C4hOC5ieC4meC4q+C4sn19IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IuC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5ieC4guC4reC4h+C4hOC4uOC4kyIsIlVzZSB0aGUgU2F2ZSBzZWFyY2guLi4iOiLguYPguIrguYnguJvguLjguYjguKHguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLLguJTguYnguLLguJnguKXguYjguLLguIfguJzguKXguKXguLHguJ7guJjguYzguIHguLLguKPguITguYnguJnguKvguLIg4LmA4Lie4Li34LmI4Lit4Lia4Lix4LiZ4LiX4Li24LiB4Lic4Lil4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LmB4Lil4Liw4Lij4Lix4Lia4LiB4Liy4Lij4Lit4Lix4Lib4LmA4LiU4LiV4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4Lir4Lih4LmIIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0g4LmD4Lir4Lih4LmIIHtjb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge+C4h+C4suC4mX0gb3RoZXIge+C4h+C4suC4mX19IOC4quC4s+C4q+C4o+C4seC4mjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJzYXZlZCBzZWFyY2ggbGFiZWwiOiLguJrguLHguJnguJfguLbguIF7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4LiyfSBvbmUge+C4geC4suC4o+C4hOC5ieC4meC4q+C4sn0gb3RoZXIge+C4geC4suC4o+C4hOC5ieC4meC4q+C4sn19IiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IuC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5ieC4guC4reC4h+C4hOC4uOC4kyIsIlVzZSB0aGUgU2F2ZSBzZWFyY2guLi4iOiLguYPguIrguYnguJvguLjguYjguKHguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLLguJTguYnguLLguJnguKXguYjguLLguIfguJzguKXguKXguLHguJ7guJjguYzguIHguLLguKPguITguYnguJnguKvguLIg4LmA4Lie4Li34LmI4Lit4Lia4Lix4LiZ4LiX4Li24LiB4Lic4Lil4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LmB4Lil4Liw4Lij4Lix4Lia4LiB4Liy4Lij4Lit4Lix4Lib4LmA4LiU4LiV4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4Lir4Lih4LmIIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6Ils8SGlkZGVuVmlzdWFsbHk+e2NvdW50TGFiZWx9IOC4geC4teC5ieG6veG6veG6vcW1IHtjb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5/FoX19IMaSw7bDtsO2xZk6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9XSIsInNhdmVkIHNlYXJjaCBsYWJlbCI6IlvFoMSDxIPEg+G5veG6veG6veG6vcaMIHt0b3RhbENvdW50LHBsdXJhbCw9MHvFoeG6veG6veG6vcSDxIPEg8WZw6fhuKl9IG9uZXvFoeG6veG6veG6vcSDxIPEg8WZw6fhuKl9IG90aGVye8Wh4bq94bq94bq9xIPEg8SDxZnDp+G4qeG6veG6veG6vcWhfX1dIiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IlvDvcO9w7bDtseax5rFmSDFocSDxIPhub3hur3hur3GjCDFoeG6veG6vcSDxIPFmcOn4bip4bq94bq9xaFdIiwiVXNlIHRoZSBTYXZlIHNlYXJjaC4uLiI6IlvHmcWh4bq94bq94bq9IOG5r+G4qeG6veG6veG6vSDFoMSDxIPEg+G5veG6veG6veG6vSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgw5/Hmseax5rhua/hua/DtsO2w7bguIHguLXguYkgw5/hur3hur3hur3GmsO2w7bDtsW1IOG5r+G4qeG6veG6veG6vSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxZnhur3hur3hur3Foceax5rHmsaa4bmvxaEg4bmvw7bDtsO2IMWhxIPEg8SD4bm94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qSDEg8SDxIPguIHguLXguYnGjCDFmeG6veG6veG6vcOn4bq94bq94bq9w6zDrMOs4bm94bq94bq94bq9IOG6veG6veG6veG5veG6veG6veG6vcWZw73DvcO9IOC4geC4teC5ieG6veG6veG6vcW1IMS1w7bDtsO2w58uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6Ils8SGlkZGVuVmlzdWFsbHk+e2NvdW50TGFiZWx9IOC4geC4teC5ieG6veG6veG6vcW1IHtjb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5/FoX19IMaSw7bDtsO2xZk6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9XSIsInNhdmVkIHNlYXJjaCBsYWJlbCI6IlvFoMSDxIPEg+G5veG6veG6veG6vcaMIHt0b3RhbENvdW50LHBsdXJhbCw9MHvFoeG6veG6veG6vcSDxIPEg8WZw6fhuKl9IG9uZXvFoeG6veG6veG6vcSDxIPEg8WZw6fhuKl9IG90aGVye8Wh4bq94bq94bq9xIPEg8SDxZnDp+G4qeG6veG6veG6vcWhfX1dIiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IlvDvcO9w7bDtseax5rFmSDFocSDxIPhub3hur3hur3GjCDFoeG6veG6vcSDxIPFmcOn4bip4bq94bq9xaFdIiwiVXNlIHRoZSBTYXZlIHNlYXJjaC4uLiI6IlvHmcWh4bq94bq94bq9IOG5r+G4qeG6veG6veG6vSDFoMSDxIPEg+G5veG6veG6veG6vSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgw5/Hmseax5rhua/hua/DtsO2w7bguIHguLXguYkgw5/hur3hur3hur3GmsO2w7bDtsW1IOG5r+G4qeG6veG6veG6vSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxZnhur3hur3hur3Foceax5rHmsaa4bmvxaEg4bmvw7bDtsO2IMWhxIPEg8SD4bm94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qSDEg8SDxIPguIHguLXguYnGjCDFmeG6veG6veG6vcOn4bq94bq94bq9w6zDrMOs4bm94bq94bq94bq9IOG6veG6veG6veG5veG6veG6veG6vcWZw73DvcO9IOC4geC4teC5ieG6veG6veG6vcW1IMS1w7bDtsO2w58uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    