import { useMemo } from 'react';

import type { SavedSearchesQueryParams } from 'src/modules/graphql/queries/types';
import useFetchSavedSearches from 'src/modules/hooks/SavedSearch';

import { resolveDisplayState } from '../utils';

const formatSummary = (queryParams: SavedSearchesQueryParams[]): string => {
  const formattedSalary =
    queryParams.find((param) => param.type === 'salary')?.value ?? '';
  const formattedWorkType =
    queryParams.find((param) => param.type === 'filter.workType')?.value ?? '';

  return `${formattedSalary}${
    formattedWorkType && formattedSalary ? ', ' : ''
  }${formattedWorkType}`;
};

const useSavedSearchesDashboard = () => {
  const { savedSearches, loading } = useFetchSavedSearches();

  const totalCount = savedSearches?.length ?? 0;
  const MAX_NUM_SAVE_SEARCHES = 3;
  const trimmedSavedSearches = useMemo(
    () =>
      Boolean(savedSearches?.length)
        ? savedSearches!.slice(0, MAX_NUM_SAVE_SEARCHES)
        : savedSearches,
    [savedSearches],
  );
  const displayState = resolveDisplayState({
    data: trimmedSavedSearches,
    loading,
  });

  return {
    displayState,
    savedSearches: trimmedSavedSearches,
    totalCount,
    formatSummary,
  };
};

export default useSavedSearchesDashboard;
