import { useTranslations } from '@vocab/react';
import { Text, TextLink } from 'braid-design-system';
import { useCallback } from 'react';

import { useReturnUrl } from '../../../../hooks/useReturnUrl.ts';
import { useAnalyticsFacade } from '../../../../modules/AnalyticsFacade';

import translations from './.vocab';

const RegisterButton = () => {
  const { t } = useTranslations(translations);

  const REGISTER = t('Register');

  const registerLink = useReturnUrl({ mode: 'register' });
  const analyticsFacade = useAnalyticsFacade();

  const onClickRegister = useCallback(() => {
    analyticsFacade.registerPressed({
      linkContext: {
        linkPosition: 'body',
        linkText: REGISTER,
      },
    });
  }, [analyticsFacade, REGISTER]);
  return (
    <Text align="center">
      <TextLink
        hitArea="large"
        href={registerLink}
        onClick={onClickRegister}
        rel="nofollow"
        data={{ automation: 'dashboardRegister' }}
      >
        {REGISTER}
      </TextLink>
    </Text>
  );
};
export default RegisterButton;
