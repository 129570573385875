import { useTranslations } from '@vocab/react';
import { Box, Inline, Text } from 'braid-design-system';
import { useCallback } from 'react';

import { quickSearchCityClicked } from 'src/store/search/search';

import translations from '../.vocab';
import type { useQuickSearch } from '../hooks/useQuickSearch';

import { QuickSearchLink } from './QuickSearchLink';

import * as styles from '../styles.css';

type MajorCitiesProps = Omit<ReturnType<typeof useQuickSearch>, 'links'> & {
  links: ReturnType<typeof useQuickSearch>['links']['majorCities'];
};

export const MajorCities = (props: MajorCitiesProps) => {
  const { t } = useTranslations(translations);
  const { dispatch, links: majorCitiesLinks } = props;

  const onCityClick = useCallback(() => {
    dispatch(quickSearchCityClicked());
  }, [dispatch]);

  return majorCitiesLinks && majorCitiesLinks.length > 0 ? (
    <Box paddingTop="xsmall" display="flex">
      <Box className={styles.rowLeftColumn}>
        <Text size="small" component="h2">
          {t('Major cities')}
        </Text>
      </Box>
      <Box>
        <Inline space="small">
          {majorCitiesLinks.map(({ name, query }) => (
            <QuickSearchLink
              key={`Jobs in ${name}`}
              linkProps={{
                ['aria-label']: t('Jobs in {name}', {
                  name,
                }),
                ['data-automation']: 'quick-search-city-link',
                analytics: 'quick-search-city',
                location: {
                  pathname: '/jobs',
                  query,
                },
                onClick: onCityClick,
              }}
            >
              {name}
            </QuickSearchLink>
          ))}
        </Inline>
      </Box>
    </Box>
  ) : null;
};
