
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiVGhlIHtsYWJlbH0gc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUuIFBsZWFzZSBnbyB0byB0aGUgPExpbms+e2xhYmVsfSBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiUmluZ2thc2FuIHtsYWJlbH0gc2FhdCBpbmkgdGlkYWsgdGVyc2VkaWEuIFNpbGFrYW4ga3VuanVuZ2kgPExpbms+aGFsYW1hbiB7bGFiZWx9PC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiUmluZ2thc2FuIHtsYWJlbH0gc2FhdCBpbmkgdGlkYWsgdGVyc2VkaWEuIFNpbGFrYW4ga3VuanVuZ2kgPExpbms+aGFsYW1hbiB7bGFiZWx9PC9MaW5rPi4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoi4Lii4Lix4LiH4LmE4Lih4LmI4Lih4Li14Liq4Lij4Li44Libe2xhYmVsfeC5g+C4meC4guC4k+C4sOC4meC4teC5iSDguYLguJvguKPguJTguYDguILguYnguLLguYTguJvguJfguLXguYg8TGluaz7guKvguJnguYnguLLguYDguJ7guIh7bGFiZWx9PC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoi4Lii4Lix4LiH4LmE4Lih4LmI4Lih4Li14Liq4Lij4Li44Libe2xhYmVsfeC5g+C4meC4guC4k+C4sOC4meC4teC5iSDguYLguJvguKPguJTguYDguILguYnguLLguYTguJvguJfguLXguYg8TGluaz7guKvguJnguYnguLLguYDguJ7guIh7bGFiZWx9PC9MaW5rPiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiW+G5ruG4qeG6veG6veG6vSB7bGFiZWx9IMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw6zDrMOsxaEgw6fHmseax5rFmcWZ4bq94bq94bq94LiB4Li14LmJ4bmvxprDvcO9w70gx5rHmsea4LiB4Li14LmJxIPEg8SD4bm9xIPEg8SDw6zDrMOsxprEg8SDxIPDn8aa4bq94bq94bq9LiDGpMaa4bq94bq94bq9xIPEg8SDxaHhur3hur3hur0gxKPDtsO2w7Yg4bmvw7bDtsO2IOG5r+G4qeG6veG6veG6vSA8TGluaz57bGFiZWx9IMalxIPEg8Sj4bq94bq9PC9MaW5rPi5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUge2xhYmVsfSBzdW1tYXJ5IGlzLi4uIjoiW+G5ruG4qeG6veG6veG6vSB7bGFiZWx9IMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw6zDrMOsxaEgw6fHmseax5rFmcWZ4bq94bq94bq94LiB4Li14LmJ4bmvxprDvcO9w70gx5rHmsea4LiB4Li14LmJxIPEg8SD4bm9xIPEg8SDw6zDrMOsxprEg8SDxIPDn8aa4bq94bq94bq9LiDGpMaa4bq94bq94bq9xIPEg8SDxaHhur3hur3hur0gxKPDtsO2w7Yg4bmvw7bDtsO2IOG5r+G4qeG6veG6veG6vSA8TGluaz57bGFiZWx9IMalxIPEg8Sj4bq94bq9PC9MaW5rPi5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    